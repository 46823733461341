import gql from 'graphql-tag'

// ---------------------------- Homepages --------------------------------------------

export const homepageEnglishQuery = gql`
query homeQuery {
  homepageCounties (sort: "displayPosition") {
    id
    speech    
    pageName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        textList      
      }
    }
    displayIcon{
      id
      url
    }
  }
}
`

// ------------------------------ Faqs ----------------------------------------------

export const faqEnglishQuery = gql`
query faqQuery {
  faqs {
    id
    question
    answer
    category
    showMap
    mapName
  }
}
`
export const faqCategoryEnglishQuery = gql`
query faqCategoryQuery {
  faqCategories(sort: "displayOrder") {
    id
    categoryName
    displayType
    displayOrder
  }
}
`

// ------------------------------ Maps ----------------------------------------------
export const mapEnglishQuery = gql`
query mapQuery {
  mapKioskMains{
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorEnglishQuery = gql`
query floorQuery {
  floorKioskMains (sort: "floorNumber"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`

export const departmentEnglishQuery = gql`
query departmentQuery {
  departmentKioskMains { 
    id
  	departmentName
    speech
    departmentMap{
      id
      url
    }
  }
}
`

export const onlineServiceEnglishQuery = gql`
query onlineServiceQuery {
  onlineServices { 
    id
  	serviceName
    serviceLink
  }
}
`
