<template>
  <div>
    <v-row class="mt-n7" no-gutters align="center" justify="center">
      <v-col cols="6">
        <v-card flat>
          <v-card-text class="d-flex justify-center pa-0">
            <v-text-field
              placeholder="Enter a keyword to search for FAQ"
              class="text-h5"
              hide-details
              outlined
              height="55px"
              rounded
              v-model="searchKeyword"
              @click="expand = !expand"
              :append-icon="searchBarIcon"
              @click:append="searchKeyword = ''; expand = false"
            >
            </v-text-field>
          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard
                class="keyboard"
                @onKeyPress="onKeyPress"
                @onChange="onChange"
              >
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>

      <v-col cols="12" align="center">
        <v-card
          v-if="expand || searchKeyword !== ''"
          @click="expand = false"
          :ripple="false"
          class="mt-2 containerBorder"
          width="95%"
          flat
          :height="cardHeight"
        >
          <v-card-text v-if="matchingFaqs.length === 0" class="pa-0">
            <span class="text-h4"> No matching Question/Keyword found. </span>
          </v-card-text>
          <div v-else>
            <v-expansion-panels class="px-6" focusable dark>
              <v-expansion-panel
                v-for="(item, index) in matchingFaqs"
                class="mt-2"
                :key="index"
              >
                <v-expansion-panel-header
                  color="primary"
                  class="white--text headline"
                  @click="clickedFaq(item.question)"
                >
                  {{ item.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  class="black--text px-2 pt-2 text-justify text-h5"
                  color="white"
                >
                  <v-row class="fill-height" no-gutters>
                    <v-col cols="11">
                      <span v-html="item.answer"></span>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        tile
                        color="primary"
                        @click="showMap(item.mapName)"
                        v-if="item.showMap"
                      >
                        <v-icon left dark> mdi-map-search </v-icon>
                        Show Map
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card>

        <v-card class="mt-1" width="95%" flat v-else>
          <v-tabs height="80" color="primary" v-model="tab">
            <v-tab
              active-class="tabClass"
              v-for="item in $t('faqCategory')"
              :key="item.id"
              class="text-h6 pa-5"
              @click="expand = false"
            >
              {{ item.categoryName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="type in $t('faqCategory')" :key="type.id">
              <v-card height="560" class="containerBorder" flat>
                <v-expansion-panels
                  v-if="type.displayType === 'expansionPanel'"
                  class="px-6"
                  focusable
                  dark
                >
                  <v-expansion-panel
                    v-for="(item, index) in filteredFaqs(type.categoryName)"
                    class="mt-2"
                    :key="index"
                  >
                    <v-expansion-panel-header
                      color="primary"
                      class="white--text headline"
                      @click="clickedFaq(item.question)"
                    >
                      {{ item.question }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="black--text px-2 pt-2 text-justify text-h5"
                      color="white"
                    >
                      <v-row class="fill-height" no-gutters>
                        <v-col cols="11">
                          <span v-html="item.answer"></span>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            tile
                            color="secondary"
                            @click="showMap(item.mapName)"
                            v-if="item.showMap"
                          >
                            <v-icon left dark> mdi-map-search </v-icon>
                            Show Map
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: "faqs",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      tab: 0,
      input: "",
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 560,
    };
  },
  computed: {
    ...mapGetters(["getQnaKeyword"]),
    matchingFaqs() {
      return this.$i18n
        .t("faqs")
        .filter((faq) =>
          faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
    },
  },
  watch: {
    expand(newVal) {
      if (newVal) {
        this.searchBarIcon = "mdi-close-circle";
        this.cardHeight = 380;
      } else {
        this.searchBarIcon = "mdi-magnify";
        this.cardHeight = 560;
      }
    },
    getQnaKeyword(newVal) {
      this.$i18n.t("faqCategory").forEach((category) => {
        if (category.categoryName.toLowerCase() === newVal) {
          this.tab = category.displayOrder;
        }
      });
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    filteredFaqs(category) {
      return this.$i18n
        .t("faqs")
        .filter((faq) =>
          faq.category.toLowerCase().includes(category.toLowerCase())
        );
    },
    showMap(map) {
      console.log('Map', map)
      this.$i18n.t("maps").forEach(mapper => {
        if(mapper.mapName.toLowerCase().trim() === map.toLowerCase().trim()){
          console.log(mapper)
        }

      })
      var mapPath = this.$i18n.t("maps").get(map.toLowerCase().trim());     
      console.log('Map', mapPath) 
      this.$store.dispatch("openImageViewer", {
        name: mapPath.mapName,
        url: mapPath.mapImage,
      });
      this.$store.dispatch("avatarSpeak", mapPath.speech);
    },

    // Touch Usage
    clickedFaq(question) {
      this.$store.commit("setTouchRequest", {
        module: "Frequently Asked Question",
        action: "Show Faq",
        response: question,
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 70)}...`;
    },
  },
  mounted() {
    this.$i18n.t("faqCategory").forEach((category) => {
      if (category.categoryName.toLowerCase() === this.getQnaKeyword) {
        console.log("Category to be displayed", category.displayOrder);
        this.tab = category.displayOrder;
      }
    });
  },
};
</script>

<style>
.tabClass {
  background-color: #2c6b87 !important;
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 0px !important;
  overflow-y: scroll;
}

.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #2c6b87 !important;
  overflow-y: scroll;
  border-radius: 15px !important;
}
</style>
