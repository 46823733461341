import {
    floorSpanishQuery,
    mapSpanishQuery,
    faqSpanishQuery,
    faqCategorySpanishQuery,
    homepageSpanishQuery,
    departmentSpanishQuery,
    onlineServiceSpanishQuery
} from '@/graphql/querySpanish';

export default {
    state: {
        homepageSpanish: [],
        floorsSpanish: [],
        mapsSpanish: new Map(),
        faqsSpanish: [],
        faqCategorySpanish: [],
        departmentsSpanish: [],
        onlineServicesSpanish: []
    },
    getters: {
        getHomepageSpanish: state => state.homepageSpanish,
        getFloorsSpanish: state => state.floorsSpanish,
        getMapsSpanish: state => state.mapsSpanish,
        getFaqsSpanish: state => state.faqsSpanish,
        getFaqCategoriesSpanish: state => state.faqCategorySpanish,
        getDepartmentsSpanish: state => state.departmentsSpanish,
        getOnlineServicesSpanish: state => state.onlineServicesSpanish
    },
    mutations: {
        setHomepageSpanish(state, page) {
            state.homepageSpanish.push(page)
        },
        setFloorsSpanish(state, floor) {
            if (state.floorsSpanish.length > 0 && floor.floorNumber === 1) {
                state.floorsSpanish.unshift(floor)
            } else {
                state.floorsSpanish.push(floor)
            }
            //state.floorsSpanish.push(floor)
        },
        setMapsSpanish(state, map) {
            state.mapsSpanish.set(map.mapName.toLowerCase().trim(), map)
        },
        setFaqsSpanish(state, faqs) {
            faqs.forEach(faq => {
                let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, '<br />'),
                    category: faq.category,
                    showMap: faq.showMap,
                    mapName: faq.mapName
                }
                state.faqsSpanish.push(data)
            })
            //state.faqs = faqs
        },
        setFaqCategorySpanish(state, faqCategories) {
            state.faqCategorySpanish = faqCategories
        },
        setDepartmentsSpanish(state, department) {
            state.departmentsSpanish.push(department)
        },
        setOnlineServicesSpanish(state, onlineService) {
            state.onlineServicesSpanish.push(onlineService)
        }
    },
    actions: {
        fetchHomePageSpanish({ commit, dispatch, getters }) {

            getters.getApolloClient.query({
                query: homepageSpanishQuery
            }).then(items => {
                items.data.homepageCounties.forEach(page => {
                    dispatch('saveInCache', page.displayIcon.url)
                        .then(response => {
                            page.displayIcon.url = response
                            commit('setHomepageSpanish', page);
                        })
                })

            })
        },
        // Fetch Floor from strapi
        fetchFloorsSpanish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: floorSpanishQuery
            }).then(floors => {
                floors.data.floorKioskMains.forEach(floor => {
                    dispatch('saveInCache', floor.floorImage.url)
                        .then(response => {
                            floor.floorImage.url = response;
                            commit('setFloorsSpanish', floor)
                        })
                })

            })
        },

        // Fetching Maps from Strapi
        fetchMapsSpanish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: mapSpanishQuery
            }).then(maps => {
                maps.data.mapKioskMains.forEach(map => {
                    dispatch('saveInCache', map.mapImage.url)
                        .then(response => {
                            commit('setMapsSpanish', {
                                mapName: map.mapName,
                                mapFloor: map.mapFloor,
                                mapImage: response,
                                speech: map.speech
                            })
                        })
                })

            })
        },

        fetchFaqsSpanish({ commit, getters }) {
            getters.getApolloClient.query({
                query: faqSpanishQuery
            }).then(faqs => {
                console.log('Count of Spanish Faqs', faqs.data.faqs.length)
                commit('setFaqsSpanish', faqs.data.faqs);

            })
        },

        fetchFaqCategorySpanish({ commit, getters }) {

            getters.getApolloClient.query({
                query: faqCategorySpanishQuery
            }).then(faqCategory => {
                commit('setFaqCategorySpanish', faqCategory.data.faqCategories)

            })
        },

        fetchDepartmentsSpanish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: departmentSpanishQuery
            }).then(departments => {
                departments.data.departmentKioskMains.forEach(department => {
                    dispatch('saveInCache', department.departmentMap.url)
                        .then(response => {
                            commit('setDepartmentsSpanish', {
                                departmentName: department.departmentName,
                                departmentImage: response,
                                speech: department.speech
                            })
                        })
                })
            })
        },
        fetchOnlineServicesSpanish({ commit, getters }) {
            getters.getApolloClient.query({
                query: onlineServiceSpanishQuery
            }).then(services => {
                services.data.onlineServices.forEach(service => {
                    commit('setOnlineServicesSpanish', {
                        serviceName: service.serviceName,
                        serviceLink: service.serviceLink,
                    })
                })
            })
        }

    }
}
