<template>
    <v-row justify="center" class="fill-height pl-3">
        <!-- <v-col cols="1" align="start">
      <v-btn>
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col> -->
        <v-col cols="12" align="center">
            <v-card flat class="containerBorder" :height="iframeHeight" width="85%">
                <v-card-title class="pa-0">
                    <v-row no-gutters>
                        <v-col cols="12" class="pt-4" align="center">
                            <iframe src="https://miottawa.fidlar.com/MIOttawa/Apex.WebPortal/" width="98%"
                                :height="iframeHeight - 40" id="myframe"></iframe>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>
        </v-col>

    </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "digitalForms",
    computed: {
        ...mapGetters([
            "getTouchCount",
            "isUserLooking",
            "getKioskProfile",
            "getVideoCall",
            "getGreetingStatus",
        ]),
        iframeHeight() {
            console.log("Window Inner height", window.innerHeight - 180);
            return window.innerHeight - 310;
        },
    },
    watch: {
        getGreetingStatus(newVal) {
            if (!newVal) {
                document.getElementById("myframe").src =
                    document.getElementById("myframe").src;
            }
        },
    },
    methods: {
        reload() {
            document.getElementById("myframe").src =
                document.getElementById("myframe").src;
        }
    },
    mounted() {
        window.addEventListener("blur", () => {
            setTimeout(() => {
                if (document.activeElement.tagName === "IFRAME") {
                    this.startSession
                    this.$store.commit("setTouchRequest", {
                        module: "Default",
                        action: "Touch Detected",
                        response: "User Session Started - Touch Detected",
                        timeStamp: new Date(),
                        requestType: "Session",
                    });

                    this.$store.commit("setTouchRequest", {
                        module: "IFrame",
                        action: "Clicked on Form",
                        response: "Clicked on Form",
                        timeStamp: new Date(),
                        requestType: "Touch",
                    });
                }
            });
        });
    },
};
</script>

<style>
.containerBorder {
    border-width: 3px;
    border-style: solid;
    border-color: #9f2b00 !important;
    border-radius: 0px !important;
    margin-bottom: 10px;
}
</style>

