<template>
  <v-row align="center" justify="center">
    <v-col cols="5">
      <v-card flat height="700" class="containerBorder1">
        <v-card-title flat class="primary--text text-h4 justify-center">
          {{ $t("cardHeader1") }}
        </v-card-title>
        <v-card class="overflow-y-auto" height="88%" flat>
          <v-row no-gutters class="justify-center">
            <v-col
              cols="12"
              v-for="(service, index) in $t('onlineService')"
              :key="index"
              align="center"
            >
              <v-card flat width="95%" height="60" color="primary" class="mt-2">
                <v-row no-gutters class="fill-height" align="center">
                  <v-col cols="9" align="center">
                    <v-card-text class="white--text text-h6">
                      {{ service.serviceName }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="3" align="center">
                    <v-btn
                      tile
                      @click="
                        sendLink(service.serviceLink, service.serviceName)
                      "
                      outlined
                      class="white--text"
                    >
                      <v-icon left dark> mdi-email </v-icon>
                      {{ $t("link1") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-col>

    <v-col cols="5">
      <v-card flat height="700" class="containerBorder1">
        <v-card-title class="primary--text text-h4 justify-center">
          {{ $t("cardHeader2") }}
        </v-card-title>
        <v-row no-gutters class="justify-center">
          <v-col cols="12" align="center" class="mt-5"> 
            <v-btn class="mb-10 white--text text-h6" width="450" height="80" color="primary" @click="$router.push('/digitalForms'); $store.commit('setPageHeader', 'Digital Forms')"> Fill out the forms here</v-btn>  
            <v-divider class="mx-5 mb-10"></v-divider>
          </v-col>
          
          <v-col cols="6" align="center" class="mt-3">
            <v-img src="@/assets/qrcode.svg" width="260" contain></v-img>
          </v-col>
          <v-col cols="12" align="center">
            <v-card-text class="text-caption grey--text">
              Disclaimer: All forms are managed outside of this kiosk by Fidlar Technologies.
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
export default {
  name: "forms",
  data() {
    return {
      value: "https://miottawa.fidlar.com/MIOttawa/Apex.WebPortal/",
      size: 300,
    };
  },
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  methods: {
    sendLink(url, name) {
      this.$store.dispatch("avatarSpeak", "Please enter your Email ID to get this link.");
      this.$store.dispatch("openEmailViewer", {
        pdfType: "link",
        pdfUrl: url,
        pdfName: name,
      });
      this.$store.commit("setTouchRequest", {
        module: "Online Services",
        action: "Open Email Viewer",
        response: "name",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 60)}...`;
    },
  },
  mounted() {},
};
</script>

<style>
.formfolder {
  border-radius: 15px !important;
}

.containerBorder1 {
  border-width: 2px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 8px !important;
}
</style>

<i18n>
{
  "en": {
      "cardHeader1": "Email links to Online Services",
      "cardHeader2": "Forms: Marriage Application, Notary and CPL",
      "link1": "Email link",
      "alter": "OR"
    },
  "es":{
      "cardHeader1": "Servicios en Línea",
      "cardHeader2": "Formas: Marriage Application, Notary and CPL",
      "link1": "Email link",
      "alter": "O"
    }
}
</i18n>