<template>
  <v-row
    :style="{ display: showRecognitionPage }"
    align="start"
    justify="center"
    no-gutters
    class="fill-height"
  >
    <v-col class="text-h3" cols="12" align="center"> Recognition </v-col>
    <v-col col="12" align="center">
      <video
        id="fdVideo"
        playsinline
        style="
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          background-color: black;
        "
      ></video>
    </v-col>
    <v-col cols="12">
      <v-row justify="center" class="fill-height">
        <v-col cols="6">
          <v-form ref="configForm" lazy-validation class="px-6">
            <v-row class="fill-height" no-gutters>
              <v-col cols="12" align="center" class="text-h4"
                >Configuration</v-col
              >
              <v-col cols="6">
                <v-text-field
                  label="Video Box Width"
                  v-model="config.videoWidth"
                  :rules="confRule"
                  hide-details
                  required
                >
                </v-text-field>
                <v-text-field
                  label="Confidence Threshold"
                  v-model="config.confidenceThreshold"
                  :rules="confRule"
                  hide-details
                  required
                >
                </v-text-field>
                <v-text-field
                  label="Detection Distance"
                  v-model="config.detectionDistance"
                  :rules="confRule"
                  hide-details
                  required
                >
                </v-text-field>
                <v-text-field
                  label="Orientation Threshold"
                  v-model="config.orientationThreshold"
                  :rules="confRule"
                  hide-details
                  required
                >
                </v-text-field>
                <v-btn @click="saveConfig" color="primary" dark block large>
                  Save
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Video Box Height"
                  v-model="config.videoHeight"
                  :rules="confRule"
                  hide-details
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="6">
          <v-row
            align="center"
            justify="center"
            class="fill-height text-h5"
            no-gutters
          >
            <v-col cols="12" align="center" class="text-h4 pb-4"
              >Observations</v-col
            >
            <v-col align="end" cols="6">Face Detected</v-col
            ><v-col align="start" cols="6" class="pl-5">{{
              faceDetected
            }}</v-col>
            <v-col align="end" cols="6">Face Confidence</v-col
            ><v-col align="start" cols="6" class="pl-5"
              >{{ faceProbability }}%</v-col
            >
            <v-col align="end" cols="6">Face Count</v-col
            ><v-col align="start" cols="6" class="pl-5">{{ faceCount }}</v-col>
            <v-col align="end" cols="6">Face Distance</v-col
            ><v-col align="start" cols="6" class="pl-5"
              >{{ faceDistanceMeters }} Meters</v-col
            >
            <v-col align="end" cols="6">Face Orientation</v-col
            ><v-col align="start" cols="6" class="pl-5">{{
              faceOrientation
            }}</v-col>
            <v-col align="end" cols="6">Right EE Distance</v-col
            ><v-col align="start" cols="6" class="pl-5">{{
              reeDistance
            }}</v-col>
            <v-col align="end" cols="6">Left EE Distance</v-col
            ><v-col align="start" cols="6" class="pl-5">{{
              leeDistance
            }}</v-col>
            <v-col align="end" cols="6"></v-col
            ><v-col align="center" cols="6"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import * as tf from "@tensorflow/tfjs";
const blazeFace = require("@tensorflow-models/blazeface");
import { mapGetters } from "vuex";
import router from "@/router";
export default {
  name: "recognition",
  data() {
    return {
      fdVideo: null,
      returnTensors: false,
      flipHorizontal: true,
      annotateBoxes: true,
      faceDetected: false,
      faceProbability: 0,
      faceDistance: 0,
      faceCount: 0,
      faceDistanceMeters: 0,
      faceOrientation: undefined,
      reeDistance: 0,
      leeDistance: 0,
      confRule: [(v) => !!v || "Cannot left empty"],
      config: {
        videoWidth: 600,
        videoHeight: 500,
        frameRate: 20,
        confidenceThreshold: 98,
        detectionDistance: 1.8,
        orientationThreshold: 30,
      },
      speechRecognition: undefined,
      transcript: undefined,
      timeoutId: 0,
      onResult: false,
    };
  },
  computed: {
    ...mapGetters([
      "enableSpeechRecognition",
      "enableFaceDetection",
      "speechTranscript",
      "speechRecognitionHandler",
      "enableAllRecognition",
      "showRecognitionPage",
      "getGreetingStatus",
      "getResetKioskStatus",
      "getTouchCount",
    ]),
  },
  watch: {
    enableAllRecognition(newVal) {
      if (newVal) {
        console.log("initiated FD and SR");
        this.setupFaceDetection();
        this.setupSpeechRecognition();
      } else {
        this.$store.commit("setEnableSpeechRecognition", false);
        this.$store.commit("setEnableFaceDetection", false);
      }
    },
    enableSpeechRecognition(newVal) {
      if (newVal) {
        this.startSpeechRecognition();
      } else {
        this.stopSpeechRecognition();
      }
    },
    speechRecognitionHandler(newVal) {
      if (newVal) {
        this.startSpeechRecognition();
      } else if (!newVal) {
        this.stopSpeechRecognition();
      }
    },
    $route(to, from) {
      if (to.path === "/rec")
        this.$store.commit("setShowRecoginitionPage", "block");
      if (from.path === "/rec")
        this.$store.commit("setShowRecoginitionPage", "none");
    },
    getGreetingStatus(newVal) {
      if (!newVal) {
        this.$i18n.locale = "en";
      }
    },
    getTouchCount(newVal) {
      if (newVal === 0) {
        this.$i18n.locale = "en";
      }
    },
  },
  methods: {
    setupFaceDetection() {
      this.fdVideo = document.getElementById("fdVideo");
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: this.config.videoWidth,
            height: this.config.videoHeight,
            frameRate: { max: this.frameRate },
          },
        })
        .then((stream) => {
          this.fdVideo.srcObject = stream;
          this.fdVideo.play().then(() => {
            this.fdVideo.width = this.fdVideo.videoWidth;
            this.fdVideo.height = this.fdVideo.videoHeight;
            tf.setBackend("webgl").then(() => {
              console.log("Webgl is the backend");
              blazeFace.load().then((model) => {
                console.log("BlazeFace loaded");
                setInterval(() => {
                  if (this.enableFaceDetection) this.prediction(model);
                }, 200);
              });
            });
          });
        });
    },
    prediction(model) {
      model
        .estimateFaces(
          this.fdVideo,
          this.returnTensors,
          this.flipHorizontal,
          this.annotateBoxes
        )
        .then((predictions) => {
          this.$store.commit("setFaceDetectionStatus", true);
          this.faceCount = predictions.length;
          this.$store.commit("setFaceCount", this.faceCount);
          if (this.faceCount > 0) {
            this.faceDetected = true;
            for (let i = 0; i < this.faceCount; i++) {
              this.faceProbability = (
                predictions[i].probability[0] * 100
              ).toFixed(1);
              const faceBoxStart = predictions[i].topLeft;
              const faceBoxEnd = predictions[i].bottomRight;
              this.faceDistance = (
                ((faceBoxStart[0] - faceBoxEnd[0]) / this.fdVideo.width) *
                100
              ).toFixed(2);
              this.faceDistanceMeters = (
                this.scale(this.faceDistance, 90, 20, -10, 100) / 100
              ).toFixed(2);
              if (
                this.faceDistanceMeters <= this.config.detectionDistance &&
                this.faceProbability >= this.config.confidenceThreshold
              ) {
                this.$store.commit("setIsUserApproached", true);
                this.reeDistance = (
                  predictions[i].landmarks[4][0] -
                  predictions[i].landmarks[0][0]
                ).toFixed(2);
                this.leeDistance = (
                  predictions[i].landmarks[1][0] -
                  predictions[i].landmarks[5][0]
                ).toFixed(2);
                if (
                  this.reeDistance < this.config.orientationThreshold ||
                  this.leeDistance < this.config.orientationThreshold
                ) {
                  this.$store.commit("setIsUserLooking", false);
                  if (this.reeDistance > this.leeDistance) {
                    this.faceOrientation = "Right Facing";
                  } else {
                    this.faceOrientation = "Left Facing";
                  }
                } else {
                  if (!this.getGreetingStatus && this.getTouchCount === 0) {
                    this.$store.commit("setDefaultBubbleText", true);
                    this.$store.dispatch(
                      "avatarSpeak",
                      "Hi! I am Francesca. I am here to help you, tap on the microphone and ask `What can you do?`"
                    );
                    this.$store.commit("setGreetingStatus", true);
                    this.$store.commit("setStartUserSession", new Date());

                    this.$store.commit("setTouchRequest", {
                      module: "Default",
                      action: "Face Detected",
                      response: "User Session Started - Face Detected",
                      timeStamp: new Date(),
                      requestType: "Session",
                    });
                  } else if (
                    this.getTouchCount > 0 &&
                    !this.getGreetingStatus
                  ) {
                    this.$store.commit("setResetKioskStatus", false);
                    this.$store.commit("setGreetingStatus", true);
                    this.$store.dispatch("clearResetTimeout");

                    this.$store.commit("setTouchRequest", {
                      module: "Default",
                      action: "Face Detected",
                      response: "Session changed to Face Detection Handling",
                      timeStamp: new Date(),
                      requestType: "Session",
                    });
                  } else if (
                    this.getTouchCount >= 0 &&
                    this.getGreetingStatus
                  ) {
                    this.$store.commit("setResetKioskStatus", false);
                    this.$store.dispatch("clearResetTimeout");
                  }
                  this.faceOrientation = "Center Facing";
                  this.$store.commit("setIsUserLooking", true);
                }
              } else {
                this.reeDistance = 0;
                this.leeDistance = 0;
                this.faceOrientation = undefined;
                this.$store.commit("setIsUserApproached", false);
                if (this.getGreetingStatus && !this.getResetKioskStatus) {
                  if(router.currentRoute.path !== '/telepresence'){
                    //this.$store.dispatch("resetKiosk");
                  }
                }
              }
            }
          } else {
            this.faceDistance = 0;
            this.faceProbability = 0;
            this.faceDistanceMeters = 0;
            this.faceDetected = false;
            this.$store.commit("setIsUserApproached", false);
            this.$store.commit("setIsUserLooking", false);
            if (this.getGreetingStatus && !this.getResetKioskStatus) {
              //this.$store.dispatch("resetKiosk");
            }
          }
        });
    },
    scale(number, inMin, inMax, outMin, outMax) {
      return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },
    setupSpeechRecognition() {
      const speechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const SpeechGrammarList =
        window.SpeechGrammarList || window.webkitSpeechGrammarList;
      if (!speechRecognition) console.log("Speech Recognition not supported");
      //has to be changed court wise
      var nouns = [
        "Francesca",
        "Judge",
        "Break Room",
        "Room",
        "Board",
        "Conference",
        "Corporate Counsel",
        "Clerk",
        "Register of Deeds",
        "Administrator",
        "Treasurer",
        "Equalization",
        "Fiscal Services",
        "GIS",
        "Elevator",
        "Bathroom",
        "Facilities",
        "Maintenance",
        "Human Resource",
        "Innovation",
        "MSU",
        "Sheriff",
        "Water Resource Commissioner",
        "Parks and Recreation",
        "Planning and Performance Improvement",
      ];
      //var grammar = '#JSGF V1.0; grammar nouns; public <nouns> = ' + nouns.join(' | ') + ' ;∂';
      var grammar =
        "#JSGF V1.0; grammar nouns; public <nouns> =  nouns.join(" | ") + ';' ";
      this.speechRecognition = new speechRecognition();
      const speechRecognitionList = new SpeechGrammarList();
      speechRecognitionList.addFromString(grammar, 1);
      this.speechRecognition.grammars = speechRecognitionList;
      this.speechRecognition.lang = "en-US";
      this.speechRecognition.continous = true;
      this.speechRecognition.interimResults = true;
      this.processSpeechRecogniton();
      //   this.startSpeechRecognition(); // remove this once in actual code.
    },
    processSpeechRecogniton() {
      this.speechRecognition.onstart = () => {
        this.onResult = false;
        console.log("Speech Recognition Started");
        this.$store.commit("setTextBubbleText", "I am listening...");
        this.$store.commit("setSpeechRecognitionStatus", true);
      };
      this.speechRecognition.onend = () => {
        console.log("Speech Recognition Ended");
        if (!this.onResult) {
          this.$store.commit(
            "setTextBubbleText",
            "Tap the microphone button, to ask me a question."
          );
          this.onResult = false;
        }

        this.$store.commit("setSpeechRecognitionHandler", null);
        this.$store.commit("setSpeechRecognitionStatus", false);
        // if(this.enableSpeechRecognition)
        //   this.startSpeechRecognition();
        // ToDO - this will prevent speech recognition from automatically initiating.
      };

      this.speechRecognition.onresult = (event) => {
        this.onResult = true;
        let spResult = event.results[event.resultIndex];
        let spTranscript =
          event.results[event.results.length - 1][0].transcript;
        this.$store.commit("setTextBubbleText", spTranscript);

        if (spResult.isFinal) {
          if (spTranscript.split(" ").length <= 15) {
            console.log("QUERY:", spTranscript);
            this.$store.dispatch("requestQnA", spTranscript);
            this.$store.commit("setSpeechTranscript", spTranscript);
            this.$store.commit("setTextBubbleText", spTranscript);
          } else {
            this.$store.commit("setSpeechTranscript", "");
            this.$store.commit("setTextBubbleText", "");
          }
        }
      };
    },
    startSpeechRecognition() {
      try {
        this.speechRecognition.stop();
        this.speechRecognition.start();
        // this.processSpeechRecogniton();
      } catch (error) {
        console.log(error.message);
      }
    },
    stopSpeechRecognition() {
      try {
        this.speechRecognition.stop();
      } catch (error) {
        console.log(error.message);
      }
    },
    saveConfig() {
      if (this.$refs.configForm.validate()) {
        this.$store.dispatch("saveConfig", this.config).then(
          (response) => {
            window.location.reload();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
  },
  mounted() {
    console.log("REC Mounted");
    // ToDo - Move this to watch to be controlled by allRecognition Flag
    // this.setupFaceDetection()
    // this.setupSpeechRecognition()
  },
};
</script>

<style>
</style>
